import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';
import { H2 } from '../../typography/H2';
import { StyledLink } from '../nav-link/nav-link.styles';

const errorContentMobileStyles = css`
    align-items: center;
    text-align: center;
    width: auto;
`;

export const StyledPageNotFound = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${rem(24)};
    width: 100%;
    padding: ${rem(64)} 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const StyledErrorContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: ${rem(8)};
    box-sizing: border-box;

    ${media(tablet, mobile)`${errorContentMobileStyles}`};

    ${mobile`
        padding: 0 ${rem(15)};
        max-width: ${rem(320)};
    `};

    ${StyledLink} {
        margin-top: ${rem(8)};
    }
`;

export const StyledImage = styled.img`
    width: ${rem(176)};
    height: auto;

    ${mobile`
        width: ${rem(168)};
    `};
`;

export const StyledH2 = styled(H2)`
    font-size: ${rem(24)};
    line-height: ${rem(28)};
    font-family: ${Variables.fonts.fontWideMedium};
    color: ${({ theme }) => theme.textPrimary};
    margin: 0;
    text-align: center;

    ${tablet`
        font-size: ${rem(24)};
        line-height: ${rem(28)};
    `};

    ${mobile`
        font-size: ${rem(20)};
        line-height: ${rem(24)};
    `};
`;

export const StyledContentMessage = styled.p`
    font-size: ${Variables.fontsize.newStandard};
    line-height: ${rem(24)};
    text-align: center;
    color: ${({ theme }) => theme.textSecondary};
    margin: 0;
    max-width: ${rem(400)};
`;

export const StyledButtonError = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    ${mobile`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 132px;
    `};
    button {
      margin: 0 auto;
      padding: ${rem(10)} ${rem(18)};
      width: ${rem(198)};
      ${mobile`
        width: calc(100% - ${rem(40)});
    `};
    }
`;
