import {
    articlesSearch,
    createArticleRating,
    getArticle,
    getArticleBySefUrl,
    getArticleSection,
    getArticleSectionBySefUrl,
    getRatingReasons,
    updateArticleRating,
    getIsProductHidden,
} from '../repositories/article.repository';

export const ArticleService = {
    getArticle: getArticle,
    getArticleBySefUrl: getArticleBySefUrl,
    articlesSearch: (
        searchText?: string,
        page = 0,
        pageSize = 5,
        productCodes?: string[],
        segmentsCodes?: string[],
        isProductHidden?: boolean,
        additional?: URLSearchParams,
    ) => {
        const params = {
            searchText,
            page,
            pageSize,
        };

        let queryString = Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([param, value]) => `${param}=${value}`)
            .join('&');

        if (productCodes?.length) {
            const productCodesString = productCodes
                .map((productCode) => `productCode=${productCode}`)
                .join('&');
            queryString = `${queryString}&${productCodesString}`;
        }

        if (segmentsCodes?.length) {
            const segmentCodesString = segmentsCodes.map(segmentCode => `segmentCode=${segmentCode}`);
            queryString = `${queryString}&${segmentCodesString}`;
        }

        if (additional) {
            queryString = `${queryString}&${additional.toString()}`;
        }

        if (isProductHidden) {
            queryString = `${queryString}&ignoreInactiveProducts=false`;
        }

        return articlesSearch(queryString);
    },
    getArticleSection,
    getArticleSectionBySefUrl,
    createArticleRating,
    updateArticleRating,
    getRatingReasons,
    getIsProductHidden,
};
