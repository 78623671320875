import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
    StyledButtonError,
    StyledContentMessage,
    StyledErrorContent,
    StyledH2,
    StyledImage,
    StyledPageNotFound,
} from './page-not-found.styles';
import { useDevice } from '../../../context/device-context';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { useNotFound } from '../../../custom-hooks/use-not-found.hook';
import { useHeader404EnvsHack } from '../../../custom-hooks/useHeader404EnvsHack.hook';
import { HttpStatusCodesEnum } from '../../../models/enums/http-status-codes.enum';
import { ErrorPageProps } from '../../../pages/_error';
import Button from '../../form-controls/button/button';

const PageNotFound: React.FC<ErrorPageProps> = ({ error }) => {
    const router = useAppRouter();
    const themeContext = useContext(ThemeContext);
    const envsEnabled = useHeader404EnvsHack();
    const { isMobile } = useDevice();

    const { regionLimited } = HttpStatusCodesEnum;
    const { url, title } = error?.data || {};
    const urlValue = url || '/';
    const isRegionLimited = error?.statusCode === regionLimited;

    useNotFound(isRegionLimited, envsEnabled);

    const homeHandler = () => {
        router.push(urlValue);
    };

    return envsEnabled ? (
        <>
            <StyledPageNotFound>
                <StyledImage src={themeContext.errorPageImage} alt="страница не найдена" />
                <StyledErrorContent>
                    <StyledH2>
                        {title ? title : 'Страница не найдена'}
                    </StyledH2>
                    {!isRegionLimited && <StyledContentMessage>
                    Страница была удалена, перенесена в&nbsp;другое место или&nbsp;никогда не&nbsp;существовала
                    </StyledContentMessage>}
                </StyledErrorContent>
                {!isMobile &&
                    <StyledButtonError>
                        <Button onClick={homeHandler}>Перейти на главную</Button>
                    </StyledButtonError>
                }
            </StyledPageNotFound>
            {isMobile &&
                <StyledButtonError>
                    <Button onClick={homeHandler}>Перейти на главную</Button>
                </StyledButtonError>
            }
        </>
    ) : null;
};

export default PageNotFound;
