import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { RouterHelper } from '../helpers/router-helper';
import { ErrorAnalytics } from '../helpers/web-analytics/error-analytics';
import { WebAnalyticsEventLabelsEnum, WebAnalyticsFilterEnum } from '../models/enums/web-analytics.enum';
import { setCurrentProduct } from '../redux/article/article-actions';
import { selectCurrentProduct } from '../redux/article/article-selectors';
import { ProductService } from '../services/product.service';

export const useNotFound = (isRegionLimited: boolean, envsEnabled: boolean) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const productIsPresent = RouterHelper.checkOnProduct();
    const productSelector = useSelector(selectCurrentProduct);

    const productSEFUrl = isRegionLimited ?
        RouterHelper.getProductSEFUrl() :
        router.asPath.split('/')[1];

    const updateProductData = async () => {
        const product = await ProductService.getProductBySEFUrl(productSEFUrl as string);
        const widgetChat = await ProductService.getChatWidgetStatus(product.code);

        dispatch(
            setCurrentProduct({
                ...product,
                widgetChat: widgetChat,
            }),
        );
    };

    useEffect(() => {
        if (envsEnabled) {
            updateProductData();
        }
    }, [envsEnabled]);

    useEffect(() => {
        if (productSelector.name) {
            ErrorAnalytics.notFoundEvent(
                isRegionLimited ? WebAnalyticsEventLabelsEnum.regionLimited : WebAnalyticsEventLabelsEnum.noPage,
                isRegionLimited ? (productIsPresent ? WebAnalyticsFilterEnum.product : WebAnalyticsFilterEnum.statya) : null,
            );
        }
    }, [productSelector]);
};
