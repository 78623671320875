import styled from 'styled-components';
import { rem } from '../../helpers/rem-converter';
import { mobile } from '../../styles/themes/media';
import Variables from '../../styles/variables';

const H2 = styled.h2`
    font-size: ${rem(24)};
    line-height: 1.1;
    font-family: ${Variables.fonts.fontMedium};
    color: ${Variables.colors.blackColor};

    ${mobile`font-size: ${rem(20)}`};
`;

export { H2 };
