import { isServer } from './isServer';
import { withUtm } from './with-utm';

const tel = 'tel:';
const hrefFromTagRegexp = /<a.*?href=\\?"([a-zA-Zа-яА-Я0-9-_.0-9():/# \\%?&=;]+\\?)".*?>/ig;
const phoneExtraSymbolsRegexp = /([\\+]|[\\(]|[\\)]|[\\ ]|[\\-])/g;
const phoneWithoutExtraRegexp = /^(?:[0-9]{10,11}|[0-9-]{4})$/;

const isPhone = (link: string) => {
    const withoutSymbols = link.replace(phoneExtraSymbolsRegexp, '');
    return phoneWithoutExtraRegexp.test(withoutSymbols);
};

const isSameDomain = (link: string) => {
    const currentDomain = isServer ? process.env.BASE_URL : window.location.origin;
    return new RegExp(`^${currentDomain}`, 'i').test(link);
};

const phoneFormatter = (link: string) => {
    if (isPhone(link)) {
        const clearLink = link.replace(phoneExtraSymbolsRegexp, '');

        if (clearLink.length === 4) {
            return tel + clearLink;
        } else if (clearLink.length === 10) {
            return tel + '8' + clearLink;
        } else if (clearLink.length === 11) {
            const ruNumber = clearLink.replace(/^[\\7]/, '8');
            return tel + ruNumber;
        }
    }

    return link;
};

const withUtmFormatter = (link: string) => {
    if (link.includes('/api/v1/uploads/')) {
        return link;
    }
    if (!isPhone(link) && isSameDomain(link)) {
        return withUtm(link);
    }

    return link;
};

const linkReplacer = (match: string, href: string) => {
    const formatters: Array<(link: string) => string> = [phoneFormatter, withUtmFormatter];
    const result = formatters.reduce((prevValue, currentFormatter) => currentFormatter(prevValue), href);

    return match.replace(href, result);
};

export const editorContentFormatter = (text: string) => {
    return text && text.replace(hrefFromTagRegexp, linkReplacer);
};
