import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
    WebAnalyticsFilterEnum,
} from '../../models/enums/web-analytics.enum';
import { GtmService } from '../web-analytics.helpers';

export class ErrorAnalytics extends GtmService {
    static notFoundEvent(eventLabel: WebAnalyticsEventLabelsEnum, filterName?: WebAnalyticsFilterEnum | null) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntSupport,
            eventCategory: WebAnalyticsEventCategoryEnum.unavailable,
            eventAction: WebAnalyticsEventActionEnum.rejected,
            eventLabel: eventLabel,
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
            filterName: filterName || null,
            productName: this.productName,
            eventContent: window.location.pathname,
        });

        this.log(this.notFoundEvent.name);
    }
}
