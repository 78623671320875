import api from './api/api';
import { envService } from '../helpers/env.helper';
import { WidgetChatInterface } from '../models/chat-simple';
import { Product } from '../models/product';

const getProductBySEFUrl = async (productUrl: string, cookies?: string) => {
    const result = await api.get<Product>(`${envService.getSupportApi()}/products/by-sefurl?sefUrl=${productUrl}`, undefined, { cookies });
    return result.data;
};

const getChatWidgetStatus = async (productCode: string, cookies?: string) => {
    const result = await api.get<WidgetChatInterface>(`${envService.getSupportApi()}/products/${productCode}/chat-widget`, undefined, {
        cookies,
    });
    return result.data;
};

export { getProductBySEFUrl, getChatWidgetStatus };
